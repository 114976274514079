<template>
  <section class="m-setting-clause m-scroll">
    <!-- 头部导航 -->
    <m-header title="Clause" ></m-header>
    <div class="main">
        <h1>
            <span>MC INTERNATIONAL ENTERPRISES LTD.</span>
        </h1>
        <p>
            <strong><span>Shipping Policy</span></strong>
        </p>
        <p>
            <span>Last updated: September 07, 2018</span>
        </p>
        <p class="t-a-l">
            <span>MoobyYoho.Thank you for visiting and shopping at MoobyYoho.com. Following are the terms and conditions that constitute our Shipping Policy.</span>
        </p>
        <p class="t-a-l">
            <span>Shipment processing time</span>
        </p>
        <p class="t-a-l">
            <span>All orders are processed within 1-2 business days. Orders are not shipped or delivered on Sunday or holidays.</span>
        </p>
        <p class="t-a-l">
            <span>If we are experiencing a high volume of orders, shipments may be delayed by a few days. Please allow additional days in transit for delivery. If there will be a significant delay in shipment of your order, we will contact you via email or telephone.</span>
        </p>
        <p class="t-a-l">
            <span class="f-f-b">&nbsp;</span>
        </p>
        <p class="t-a-l">
            <strong><span>Shipping rates &amp; delivery estimates</span></strong>
        </p>
        <p class="t-a-l">
            <span>We offer free shipping service over $58 in Greater Vancouver area, $88 in Calgary and Edmonton, and $99 for other parts of Canada. And you can expect to receive your order within 2-5 business days once we process your order.</span>
        </p>
        <p class="t-a-l">
            <strong><span class="f-f">Notes: Delivery delays can occasionally occur.</span></strong>
        </p>
        <p class="t-a-l">
            <strong><span class="f-f">&nbsp;</span></strong>
        </p>
        <p class="t-a-l">
            <strong><span>Shipment confirmation &amp; Order tracking</span></strong>
        </p>
        <p class="t-a-l">
            <span>You can see your order status on Shipping Page of the website and contact customer service for tracking number once your order has shipped. The tracking number will be active within 24 hours.</span>
        </p>
        <p class="t-a-l">
            <span>&nbsp;</span>
        </p>
        <p class="t-a-l">
            <strong><span>Damages</span></strong>
        </p>
        <p class="t-a-l">
            <span>Mooby Yoho</span><span>is not liable for any products damaged or lost during shipping. If you received your order damaged, please contact the shipment carrier to file a claim.</span>
        </p>
        <p>
            <span>Please save all packaging materials and damaged goods before filing a claim.</span>
        </p>
        <p class="t-a-l">
            <span>&nbsp;</span>
        </p>
    </div>
  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'

export default {
  name:'Clause',
  components:{ MHeader },
  data(){
    return {
      
    }
  }
}
</script>

<style lang="less" scoped>
.m-setting-clause {
  width: 100%;
  height: 100%;
  background-color: #F5F9FF;
  padding-top:44px;
  overflow-y: auto;
  .main {
    margin-top: 12px;
    width: 100%;
    padding: 0 16px;
    font-size: 16px;
    >p:nth-child(2),>p:nth-child(3){margin-top:27px;margin-right:0;margin-bottom:8px;margin-left:0;text-align:left}
    .t-a-l{text-align-last: left;}
    .f-f{font-family:'&#39;微软雅黑&#39;&#39;sans-serif&#39'}
    .f-f-b{font-size:16px;font-family:'&#39;微软雅黑&#39;,&#39;sans-serif&#39';color:black;background:white}
  }
}
</style>